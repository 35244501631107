import {motion} from "framer-motion";
import BackendIc from "./images/backend.svg";
import WebIc from "./images/web.svg";
import MachineIc from "./images/machine.svg";

const plang = "underline underline-offset-4 font-semibold decoration-lm-pink decoration-2";
const ptech = "text-lm-blue font-semibold";

const Skills = () => {
    const lrAnimationVariants = {
        visible: {y: 0},
        hidden: {y: 25}
    };

    return (
        <div className="text-lm-black
                        font-Quicksand flex flex-col
                        gap-16"
        >
            <div className="flex flex-row
                            justify-center">
                <h2 className="text-2xl font-bold">
                    Skills
                </h2>
            </div>


            <div className="flex flex-col justify-center
                           items-center gap-8
                           lg:flex lg:flex-row">

                <motion.div className="flex flex-col
                                gap-4 border-2 items-center
                                rounded-md shadow-md
                                py-6 px-8 lg:w-[400px]
                                md:w-[400px] w-80
                                lg:h-[500px] md:h-[525px] h-[555px]"

                            initial="hidden"
                            whileHover="visible"
                            transition={{
                                duration: 0.3
                            }}
                            variants={lrAnimationVariants}
                >
                    <h3 className="text-lg font-semibold">Frontend Development</h3>
                    <img src={WebIc} alt="Web development" className="w-56"/>
                    <p>
                        With expertise in <span className={plang}>HTML</span>, <span className={plang}>CSS</span>, and <span className={plang}>JavaScript</span>, I build websites, ensuring not only functionality but also visual allure. <span className={ptech}>ReactJS</span> / <span className={ptech}>Tailwind</span> / <span className={ptech}>Material UI</span> are my tools of choice for creating dynamic and responsive interfaces
                    </p>
                </motion.div>

                <motion.div className="flex flex-col
                                gap-4 border-2 items-center
                                rounded-md shadow-md
                                py-6 px-8 lg:w-[400px]
                                md:w-[400px] w-80
                                lg:h-[500px] md:h-[525px] h-[555px]"
                            initial="hidden"
                            whileHover="visible"
                            transition={{
                                duration: 0.3
                            }}
                            variants={lrAnimationVariants}
                >
                    <h3 className="text-lg font-semibold">Backend Development</h3>
                    <img src={BackendIc} alt="Backend development" className="w-56"/>
                    <p>
                        I specialise in the development of <span className={ptech}>APIs</span> that serve as robust middleware, connecting mobile, web and desktop applications while ensuring high level security. My go-to backend tool is <span className={plang}>Java</span> <span className={ptech}>Spring Boot</span> which is perfect for creating the bridge between frontend and database
                    </p>
                </motion.div>

                <motion.div className="flex flex-col
                                gap-6 border-2 items-center
                                rounded-md shadow-md
                                py-6 px-8 lg:w-[400px]
                                md:w-[400px] w-80
                                lg:h-[500px] md:h-[525px] h-[555px]"
                            initial="hidden"
                            whileHover="visible"
                            transition={{
                                duration: 0.3
                            }}
                            variants={lrAnimationVariants}
                >
                    <h3 className="text-lg font-semibold">Automation</h3>
                    <img src={MachineIc} alt="Machine Learning" className="w-56"/>
                    <p>
                        As a Machine Learning enthusiast, I code in <span className={plang}>Python</span> and use <span className={ptech}>PyTorch</span> to train programs. My focus is on automating tasks traditionally handled manually, utilising the power of Machine Learning
                    </p>
                </motion.div>
            </div>
        </div>
    )
};

export default Skills;
