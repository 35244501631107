import {Link, useLocation} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import {useState} from "react";
import {IoIosCloseCircle} from "react-icons/io";

const squigglyLineShape = "M171 1L158.137 3.77443C151.336 5.24128 144.3 5.24128 137.5 3.77443L132.575 2.71235C127.375 1.5907 121.971 1.86241 116.909 3.5V3.5C111.847 5.13759 106.443 5.4093 101.243 4.28765L96.3185 3.22556C89.5177 1.75872 82.4823 1.75872 75.6815 3.22556L73.1367 3.77443C66.3359 5.24128 59.3005 5.24128 52.4997 3.77443L47.5754 2.71235C42.3751 1.5907 36.9707 1.86241 31.9091 3.5V3.5C26.8475 5.13759 21.4431 5.4093 16.2428 4.28765L1 1";

const navItems = [
    {
        id: 0,
        title: "Home",
        path: "/"

    },
    {
        id: 1,
        title: "About me",
        path: "/about"
    },
    {
        id: 2,
        title: "Portfolio",
        path: "/portfolio"
    },
    {
        id: 3,
        title: "Contact",
        path: "/contact"
    }
];

const Navbar = () => {
    const location = useLocation();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(prevState => !prevState);
    };

    return (
        <div className="fixed flex w-full justify-between items-center px-14 py-6
                        bg-lm-white text-lm-black border-b-2 z-20"
        >
            <Link to="/">
                <div className="flex flex-col items-center">
                    <div className="flex gap-1 text-3xl font-Oswald">
                        <span className="font-bold">azizbek</span>
                        <span className="font-normal">avazov</span>
                    </div>
                    <motion.svg
                        width="172"
                        height="6"
                        viewBox="0 0 172 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <motion.path
                            xmlns="http://www.w3.org/2000/svg"
                            d={squigglyLineShape}
                            stroke="#c92073"
                            strokeWidth={4}
                            strokeLinecap="round"
                            initial={{ pathLength: 0, pathOffset: 1 }}
                            animate={{ pathLength: 1, pathOffset: 0}}
                            transition={{
                                duration: 2
                            }}
                        />
                    </motion.svg>
                </div>
            </Link>

            <div className="hidden lg:flex gap-6 font-Oswald text-xl">
                {navItems.map((item, idx)=> {
                    return (
                        <Link
                            to={item.path}
                            key={idx}
                            className={`px-2 py-1 hover:bg-lm-black 
                                       hover:text-lm-white hover:rounded-md
                                       ${location.pathname === item.path ? "bg-lm-black rounded-md text-lm-white": "" }`}
                        >
                            {item.title}
                        </Link>
                    )
                })}
            </div>

            <div className="lg:hidden text-xl font-Oswald">
                <button onClick={toggleSidebar}>
                    Menu
                </button>
            </div>

            <AnimatePresence mode="wait" initial={false}>
                {isSidebarOpen && (
                    <motion.div
                        className={`absolute top-full left-0 h-screen bg-lm-white 
                                    border-t-2 border-r-2 font-Oswald text-xl
                                    min-w-48 flex flex-col gap-6`}
                        initial={{x: '-100%'}}
                        animate={{x: 0}}
                        exit={{ x: '-100%' }}
                        transition={{duration: 0.3}}
                        key="sidebar"
                    >
                        <motion.button
                            onClick={toggleSidebar}
                            className="w-fit ml-auto mt-1 mr-1"
                            initial={{ x: -300, opacity: 0, rotate: 0 }}
                            animate={{ x: 0, opacity: 1, rotate: 2*360 }}
                            transition={{
                                duration: 0.8
                            }}
                        >
                            <IoIosCloseCircle
                                className="text-lm-pink"
                                size={30}
                            />
                        </motion.button>

                        <div className="flex flex-col gap-6 items-center h-full overflow-y-auto pb-8 md:pb-0">
                            {navItems.map((item, idx)=> {
                                return (
                                    <motion.div
                                        key={idx}
                                        className="text-center p-3"
                                        initial={{opacity: 0, x: -50}}
                                        animate={{opacity: 1, x: 0}}
                                        transition={{
                                            delay: 0.2 + idx / 10
                                        }}
                                    >
                                        <Link to={item.path} className={`hover:bg-lm-black hover:text-lm-white hover:rounded-md w-full p-3
                                                                        ${location.pathname === item.path ? "bg-lm-black rounded-md text-lm-white": "" }`}>
                                            {item.title}
                                        </Link>
                                    </motion.div>
                                )
                            })}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

        </div>
    )
};

export default Navbar;
