import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Layout from "../Shared/Layout";
import {insert_project} from "../../api/admin_api";
import API_CONSTANTS from "../../api/api_constants";
import ReactQuill, {Quill} from "react-quill";
import ImageUploader from "quill-image-uploader";
import {IoArrowBackCircle} from "react-icons/io5";

Quill.register("modules/imageUploader", ImageUploader);

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "imageBlot" // #5 Optinal if using custom formats
];

const modules = {
    // #3 Add "image" to the toolbar
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
        ],
        ["link", "image"],
        ["clean"]
    ],
    // # 4 Add module and upload function
    imageUploader: {
        upload: (file) => {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append("image", file);

                fetch(
                    API_CONSTANTS.base_url + "/image/upload",
                    {
                        method: "POST",
                        body: formData
                    }
                )
                    .then((response) => response.json())
                    .then((result) => {
                        resolve(API_CONSTANTS.base_url + result.image_url);
                    })
                    .catch((error) => {
                        reject("Image upload failed");
                        console.error("Image upload Error:", error);
                    });
            });
        }
    }
};

const InsertProject = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [accessToken, setAccessToken] = useState("");
    const [projectTitle, setProjectTitle] = useState("");
    const [projectImage, setProjectImage] = useState(null);
    const [githubLink, setGithubLink] = useState("");
    const [projectTagsString, setProjectTagsString] = useState("");
    const [isVisible, setIsVisible] = useState("");
    const [projectDesc, setProjectDesc] = useState("");

    const submitProject = () => {
        const requestData = new FormData();
        requestData.append('projectTitle', projectTitle);
        requestData.append('projectImage', projectImage);
        requestData.append("projectTags", projectTagsString);
        requestData.append("projectDesc", projectDesc);
        requestData.append("visible", isVisible.toUpperCase());
        requestData.append("github", githubLink);

        insert_project(requestData).then((response)=> {
            if (API_CONSTANTS.is_debug) {
                console.log("insert_project response: ", response);
            }
        }).catch((err)=> {
            console.log("insert_project error: ", err);
        });
    };

    const isSubmitButtonDisabled = () => {
        return !projectTitle || !projectImage || !projectTagsString;
    };

    useEffect(() => {
        const accessToken = new URLSearchParams(location.search).get("accessToken");
        setAccessToken(accessToken);
        // Check if accessToken is present and valid
        if (!accessToken) {
            navigate("/");
        }
    }, [location.search]);

    return (
        <div>
            <Layout>
                <div className="font-Quicksand text-lm-black flex flex-col gap-12">
                    <Link
                        to={{
                            pathname: "/admin",
                            search: `?accessToken=${accessToken}`,
                        }}
                        className="w-fit"
                    >
                        <IoArrowBackCircle size={38} className="text-lm-pink"/>
                    </Link>
                    <h2 className="text-3xl font-bold">Insert new project to database</h2>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-2">
                            <label htmlFor="project_title" className="text-xl font-semibold">Project title</label>
                            <textarea
                                id="project_title"
                                name="project_title"
                                maxLength={130}
                                className="w-1/2 h-14 flex items-center resize-none p-3 border-2 focus:outline-none"
                                value={projectTitle}
                                onChange={(e)=> setProjectTitle(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="project_image" className="text-xl font-semibold">Project image</label>
                            <input
                                type="file"
                                name="project_image"
                                id="project_image"
                                className="w-1/2 max-h-16 resize-none p-3 border-2 focus:outline-none"
                                onChange={(e) => setProjectImage(e.target.files[0])}
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="github_link" className="text-xl font-semibold">Github link</label>
                            <input
                                type="text"
                                name="github_link"
                                id="github_link"
                                className="w-1/2 max-h-16 resize-none p-3 border-2 focus:outline-none"
                                value={githubLink}
                                onChange={(e) => setGithubLink(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="project_tags" className="text-xl font-semibold">Project tags</label>
                            <input
                                type="text"
                                name="project_tags"
                                id="project_tags"
                                className="w-1/2 max-h-16 resize-none p-3 border-2 focus:outline-none"
                                value={projectTagsString}
                                onChange={(e) => setProjectTagsString(e.target.value)}
                                placeholder="tag 1, tag 2, tag 3 ..."
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="is_visible" className="text-xl font-semibold">Is visible</label>
                            <input
                                type="text"
                                name="is_visible"
                                id="is_visible"
                                className="w-1/2 max-h-16 resize-none p-3 border-2 focus:outline-none"
                                value={isVisible}
                                maxLength={1}
                                onChange={(e) => setIsVisible(e.target.value)}
                                placeholder="Y or N"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="project_desc" className="text-xl font-semibold">Project description</label>
                            <ReactQuill
                                id="project_desc"
                                value={projectDesc}
                                onChange={(value)=> setProjectDesc(value)}
                                theme="snow"
                                placeholder="Write something awesome..."
                                style={{ height: '600px' }}
                                modules={modules}
                                formats={formats}
                            />
                        </div>

                        <button
                            className="ml-auto border-2 shadow-sm font-semibold lg:mt-8 mt-16 p-3 w-32 rounded-md text-lm-white bg-lm-black disabled:bg-gray-600"
                            onClick={submitProject}
                            disabled={isSubmitButtonDisabled()}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </Layout>
        </div>
    )
};

export default InsertProject;
