const Layout = ({children}) => {

    return (
        <div className="px-14 pt-16 lg:pt-36">
            {children}
        </div>
    )
};

export default Layout;
