import {motion} from "framer-motion";
import {FaCss3, FaHtml5, FaJava, FaPython, FaReact} from "react-icons/fa";
import {IoLogoJavascript} from "react-icons/io5";
import {BiLogoPostgresql, BiLogoSpringBoot, BiLogoTailwindCss} from "react-icons/bi";
import {SiJupyter, SiMicrosoftsqlserver, SiMysql, SiPytorch, SiSqlite} from "react-icons/si";
import {GrOracle} from "react-icons/gr";

const iconBg = "flex flex-col items-center border-2 p-3 gap-2 shadow-sm rounded-md w-24 h-20 backdrop-blur-sm bg-gray-100/60";

const Stack = () => {
    const techIconSize = 26;

    const frontendIcons = () => {
        return (
            <div className="flex flex-row gap-6 flex-wrap justify-center">
                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <FaHtml5 size={techIconSize} className="text-orange-500"/>
                    <p className="text-xs font-semibold text-sm">HTML5</p>
                </motion.div>

                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <FaCss3 size={techIconSize} className="text-blue-500"/>
                    <p className="text-xs font-semibold">CSS3</p>
                </motion.div>

                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <IoLogoJavascript size={techIconSize} className="text-yellow-500"/>
                    <p className="text-xs font-semibold">Javascript</p>
                </motion.div>

                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <BiLogoTailwindCss size={techIconSize} className="text-blue-300"/>
                    <p className="text-xs font-semibold">TailwindCSS</p>
                </motion.div>

                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <FaReact size={techIconSize} className="text-blue-600"/>
                    <p className="text-xs font-semibold">ReactJS</p>
                </motion.div>
            </div>
        )
    };

    const backendIcons = () => {
        return (
            <div className="flex flex-row gap-6 flex-wrap justify-center">
                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <FaJava size={techIconSize} className="text-red-800"/>
                    <p className="text-xs font-semibold text-sm">Java</p>
                </motion.div>

                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <BiLogoSpringBoot size={techIconSize} className="text-green-600"/>
                    <p className="text-xs font-semibold">Spring Boot</p>
                </motion.div>
                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <FaPython size={techIconSize} className="text-blue-300"/>
                    <p className="text-xs font-semibold text-sm">Python</p>
                </motion.div>

                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <SiPytorch size={techIconSize} className="text-orange-500"/>
                    <p className="text-xs font-semibold">PyTorch</p>
                </motion.div>

                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <SiJupyter size={techIconSize} className="text-orange-400"/>
                    <p className="text-xs font-semibold">Jupyter</p>
                </motion.div>
            </div>
        )
    };

    const databaseIcons = () => {
        return (
            <div className="flex flex-row gap-6 flex-wrap justify-center">
                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <BiLogoPostgresql size={techIconSize} className="text-lm-blue"/>
                    <p className="text-xs font-semibold text-sm">PostgreSQL</p>
                </motion.div>

                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <SiSqlite size={techIconSize} className="text-blue-950"/>
                    <p className="text-xs font-semibold">SQLite</p>
                </motion.div>

                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <GrOracle size={techIconSize} className="text-orange-400"/>
                    <p className="text-xs font-semibold">Oracle</p>
                </motion.div>
                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <SiMysql size={techIconSize} className="text-blue-950"/>
                    <p className="text-xs font-semibold">MySQL</p>
                </motion.div>
                <motion.div
                    className={iconBg}
                    whileHover={{scale: 1.1}}
                >
                    <SiMicrosoftsqlserver size={techIconSize} className="text-red-700"/>
                    <p className="text-xs font-semibold">SQL Server</p>
                </motion.div>
            </div>
        )
    };

    return (
        <div className="text-lm-black font-Quicksand
                       gap-16 flex flex-col"
        >
            <div className="flex flex-row
                            justify-center">
                <h2 className="text-2xl font-bold">
                    Tech stack
                </h2>
            </div>

            <div className="flex flex-col md:flex
                            md:flex-row items-center
                            md:gap-2 gap-4 justify-center">
                <h3 className="text-lg font-semibold">Frontend</h3>
                <div className="h-1 w-14 bg-lm-black
                                    rounded-md"/>
                {frontendIcons()}
            </div>

            <div className="flex flex-col md:flex
                            md:flex-row items-center
                            md:gap-2 gap-4 justify-center">
                <h3 className="text-lg font-semibold">Backend</h3>
                <div className="h-1 w-14 bg-lm-black
                                    rounded-md"/>
                {backendIcons()}
            </div>

            <div className="flex flex-col md:flex
                            md:flex-row items-center
                            md:gap-2 gap-4 justify-center">
                <h3 className="text-lg font-semibold">Database</h3>
                <div className="h-1 w-14 bg-lm-black
                                    rounded-md"/>
                {databaseIcons()}
            </div>
        </div>
    )
};

export default Stack;
