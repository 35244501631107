import API_CONSTANTS from "../../api/api_constants";

const BriefSummary = () => {

    return (
        <div
            className="relative flex flex-col gap-6 text-lm-white bg-lm-blue p-8 overflow-hidden">
            <div className="sm:flex sm:flex-row flex flex-col justify-between gap-8">
                <h2 className="text-2xl font-bold z-10">Brief summary</h2>
                <a href={API_CONSTANTS.base_url + "/AZIZBEK_CV.pdf"} target="_blank" download="AZIZBEK_CV.pdf"
                   className="px-4 py-2 font-semibold w-fit text-lm-white bg-lm-black
                                     active:bg-lm-black hover:bg-opacity-90">
                    Download CV
                </a>
            </div>
            <p className="text-lg z-10">
                I am a Full-stack software developer with over 3 years of industry experience. Originally from
                Uzbekistan, I am currently residing in the UK. My professional background involves providing support and
                developing Application Protocol Interfaces (APIs) using the Java programming language. I hold a
                Bachelor's Degree in Computer Science and a Master's Degree in Artificial Intelligence
            </p>

            <div
                className="absolute rounded-full top-4 left-7 w-16 h-16 bg-lm-pink rounded-full"
            />

            <div
                className="absolute rounded-full lg:-bottom-12 lg:left-6 -right-16 -bottom-16 w-56 h-56 bg-lm-pink rounded-full"
            />
        </div>
    )
};

export default BriefSummary;
