import API_CONSTANTS from "./api_constants";

const URL = "/admin";

export async function get_secret_token() {
    const path_segment = "/get_secret_token";
    const path = API_CONSTANTS.base_url + URL + path_segment;

    try {
        const response = await fetch(path, {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": API_CONSTANTS.auth_token
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        if (API_CONSTANTS.is_debug) {
            console.error('get_secret_token error:', error);
        }

        throw error;
    }
}

export async function insert_project(requestData) {
    const path_segment = "/insert_project";
    const path = API_CONSTANTS.base_url + URL + path_segment;

    const response = await fetch(path, {
        method: "POST",
        mode: "cors",
        body: requestData,
        headers: {
            "Accept": "application/json",
            "Authorization": API_CONSTANTS.auth_token
        }
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
}
