import API_CONSTANTS from "./api_constants";

const URL = "/projects";

export async function get_all_projects() {
    const path_segment = "/get_all";
    const path = API_CONSTANTS.base_url + URL + path_segment;

    try {
        const response = await fetch(path, {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": API_CONSTANTS.auth_token
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (API_CONSTANTS.is_debug) {
            console.log("get_all_projects response data: ", data);
        }

        return data;
    } catch (error) {
        if (API_CONSTANTS.is_debug) {
            console.error('get_all_projects error:', error);
        }

        throw error;
    }
}

export async function find_project_by_id(requestData) {
    const path_segment = "/find_by_id";
    const path = API_CONSTANTS.base_url + URL + path_segment;

    try {
        const response = await fetch(path, {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": API_CONSTANTS.auth_token
            }, body: JSON.stringify(requestData)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (API_CONSTANTS.is_debug) {
            console.log("find_project_by_id response data: ", data);
        }

        return data;
    } catch (error) {
        if (API_CONSTANTS.is_debug) {
            console.error('find_project_by_id error:', error);
        }

        throw error;
    }
}
