import Layout from "../Shared/Layout";
import {IoArrowBackCircle} from "react-icons/io5";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {find_project_by_id} from "../../api/projects_api";
import API_CONSTANTS from "../../api/api_constants";

const ProjectDetail = () => {
    const navigate = useNavigate();
    const {projectId} = useParams();

    const [project, setProject] = useState({});

    const navigateBack = () => {
        navigate(-1);
    };

    const getProject = () => {
        if (projectId) {
            const requestData = {
                id: projectId
            }
            find_project_by_id(requestData).then(response => {
                if (response && response.status === 0) {
                    setProject(response.project);
                }
            });
        }
    };

    const adjustImageStyles = () => {
        const container = document.getElementById('project-description-container');

        if (container) {
            const images = container.getElementsByTagName('img');

            for (const img of images) {
                img.className = "w-96 h-96";
            }
        }
    };

    useEffect(()=> {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);

        getProject();
    }, []);

    useEffect(()=> {
        adjustImageStyles();
    }, [project]);

    return (
        <Layout>
            <div className="text-lm-black font-Quicksand flex flex-col gap-6 pt-16 lg:pt-0">
                <button type="button" className="w-fit" onClick={navigateBack}>
                    <IoArrowBackCircle size={38} className="text-lm-pink"/>
                </button>

                <div className="flex flex-col gap-12 items-center">
                    <h2 className="font-bold text-2xl text-center">
                        {project.title}
                    </h2>

                    <div>
                        <img src={API_CONSTANTS.base_url + project.image} className="w-[550px] h-[450px] object-cover"/>
                    </div>

                    <div className="w-4/5 text-lg font-semibold">
                        <div className="flex flex-col gap-4"
                             id="project-description-container"
                             dangerouslySetInnerHTML={{ __html: project.description }} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ProjectDetail;
