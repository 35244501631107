import {AnimatePresence, motion} from "framer-motion";
import {BiError, BiSolidBadgeCheck} from "react-icons/bi";
import {IoIosCloseCircle} from "react-icons/io";

const PopupMessage = ({isOpen, status, isOpenCallback}) => {
    return (
        <AnimatePresence>
            {isOpen && (<motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="fixed top-0 left-0 w-full h-full flex z-30
                           justify-center items-center bg-black bg-opacity-50
                           font-Quicksand"
                onClick={()=> isOpenCallback(false)}

            >
                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    transition={{ duration: 0.3 }}
                    className="bg-lm-white rounded-md lg:w-[480px]"
                    onClick={(e)=> e.stopPropagation()}
                >
                    <div className="w-full flex items-end">
                        <motion.button
                            className="w-fit ml-auto mt-2 mr-2"
                            initial={{ opacity: 0, rotate: 0 }}
                            animate={{ opacity: 1, rotate: 2*360 }}
                            transition={{
                                duration: 0.8
                            }}
                            onClick={()=> isOpenCallback(false)}
                        >
                            <IoIosCloseCircle
                                className="text-lm-pink"
                                size={30}
                            />
                        </motion.button>
                    </div>

                    <div className="flex flex-col gap-6 px-4 py-6">
                        {
                            status === 0 ?
                                <div className="flex flex-row gap-2 items-center">
                                    <BiSolidBadgeCheck size={34} className="text-green-800"/>
                                    <span className="font-semibold">Message successfully sent</span>
                                </div>
                                :
                                <div className="flex flex-row gap-2 items-center">
                                    <BiError size={34} className="text-red-800"/>
                                    <span className="font-semibold">There is a problem sending the message</span>
                                </div>
                        }

                        <div className="w-full flex items-end">
                            <button type="button" className="ml-auto px-2 py-1 text-sm
                                                             w-fit text-lm-white bg-lm-black
                                                             hover:bg-opacity-90 active:bg-lm-black"

                                    onClick={()=> isOpenCallback(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>

                </motion.div>
            </motion.div>)}
        </AnimatePresence>
    )
};

export default PopupMessage;
