import {AiFillCopyrightCircle, AiFillGithub, AiFillLinkedin, AiFillMail} from "react-icons/ai";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";

const Footer = () => {

    const iconSize = 32;

    return (
        <footer className="text-lm-black font-Quicksand pt-16 h-56">
            <div className="flex flex-col gap-2 bg-lm-black h-full justify-center items-center">
                <div className="flex flex-row gap-4">
                    <motion.div
                        initial={{scale: 1}}
                        whileHover={{scale: 1.1}}
                    >
                        <Link to="https://www.linkedin.com/in/azizbekavazov/" target="_blank">
                            <AiFillLinkedin size={iconSize} className="text-lm-white"/>
                        </Link>
                    </motion.div>
                    <motion.div
                        initial={{scale: 1}}
                        whileHover={{scale: 1.1}}
                    >
                        <Link to="https://github.com/AzizbekAvazov" target="_blank">
                            <AiFillGithub size={iconSize} className="text-lm-white"/>
                        </Link>
                    </motion.div>
                    <motion.div
                        initial={{scale: 1}}
                        whileHover={{scale: 1.1}}
                    >
                        <Link to="mailto:avazovturin@gmail.com" target="_blank">
                            <AiFillMail size={iconSize} className="text-lm-white"/>
                        </Link>
                    </motion.div>
                </div>

                <div className="flex flex-row gap-2">
                    <div className="flex flex-row gap-2 items-center">
                        <AiFillCopyrightCircle size={22} className="text-lm-white"/>
                        <span className="text-lm-white text-xl">Copyright</span>
                    </div>
                    <span className="text-lm-white text-xl">Azizbek Avazov - 2024</span>
                </div>
            </div>
        </footer>
    )
};

export default Footer;
