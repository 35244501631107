import MedalIc from "./images/medal-of-award.svg";
import SmilingMonkeyIc from "./images/smiling.png";
import {motion} from "framer-motion";

const plang = "underline underline-offset-4 font-semibold decoration-lm-pink decoration-2";
const ptech = "text-lm-blue font-semibold";

const Other = () => {
    const underAward = "The lonely award above is getting a bit lonely! Don't worry; it's just the beginning";

    return (
        <div className="flex flex-col gap-12">
            <h2 className="text-2xl font-bold z-10">Awards</h2>

            <div className="flex flex-col gap-6">
                <div className="relative flex flex-col w-full items-center bg-lm-blue text-lm-white p-8">
                    <img src={MedalIc} alt="Medal" className="absolute left-2 top-2 lg:w-8 lg:h-8 md:w-7 md:h-7 w-6 h-6"/>
                    <span className="text-lg font-semibold text-center">Awarded the scholarship by the Central Bank of Uzbekistan as the victor in the student competition within the field of Information Technology</span>
                </div>
                <div className="flex flex-row gap-2 items-center justify-center flex-wrap">
                    {underAward.split(" ").map((char, idx)=> {
                        return (
                            <motion.span
                                className="italic"
                                key={idx}
                                initial={{opacity: 0}}
                                whileInView={{opacity: 1}}
                                transition={{
                                    delay: 1 + Math.floor(Math.random() * 11)/10,
                                    duration: 1
                                }}
                            >
                                {char}
                            </motion.span>
                        )
                    })}
                    <motion.img
                        src={SmilingMonkeyIc} alt="Silly monkey"
                        className="w-8 h-8"
                        initial={{opacity: 0, scale: 1, y: 0, rotate: 0}}
                        whileInView={{opacity: 1, scale: 1.2, y: [15, -15, 0], rotate: 360}}
                        transition={{
                            delay: 2,
                            duration: 1
                        }}
                    />
                </div>
            </div>

            <h2 className="text-2xl font-bold z-10">Languages</h2>

            <div className="flex flex-col gap-6">
                <div className="flex flex-row justify-center gap-12 flex-wrap">
                    <div className="flex flex-row items-center gap-2 border-2
                    pr-4 bg-gray-200 shadow-sm">
                        <div className="w-20 h-20 border-2 border-l-0 rounded-full flex
                    items-center justify-center text-lm-black font-bold
                    bg-dark-gray text-xl">01</div>
                        <span className="font-semibold text-lm-black text-lg">English: fluent</span>
                    </div>

                    <div className="flex flex-row items-center gap-2 border-2
                 pr-4 bg-gray-200 shadow-sm">
                        <div className="w-20 h-20 border-2 border-l-0 rounded-full flex
                    items-center justify-center text-lm-black font-bold
                    bg-dark-gray text-xl">02</div>
                        <span className="font-semibold text-lm-black text-lg">Uzbek: native</span>
                    </div>

                    <div className="flex flex-row items-center gap-2 border-2
                 pr-4 bg-gray-200 shadow-sm">
                        <div className="w-20 h-20 border-2 border-l-0 rounded-full flex
                    items-center justify-center text-lm-black font-bold
                    bg-dark-gray text-xl">03</div>
                        <span className="font-semibold text-lm-black text-lg">Russian: fluent</span>
                    </div>
                </div>
            </div>

            <div className="flex flex-row justify-center gap-12 flex-wrap">
                <div className="flex flex-row items-center gap-2 border-2 pr-4 shadow-sm border-gray-100">
                    <div className="w-32 h-20 flex items-center justify-center text-lm-black font-bold text-xl">May 2016</div>
                    <span className="font-semibold text-lm-black text-lg">IELTS: 6.5</span>
                </div>

                <div className="flex flex-row items-center gap-2 border-2 pr-4 shadow-sm border-gray-100">
                    <div className="w-32 h-20 flex items-center justify-center text-lm-black font-bold text-xl">June 2019</div>
                    <span className="font-semibold text-lm-black text-lg">IELTS: 8.0</span>
                </div>

                <div className="flex flex-row items-center gap-2 border-2 pr-4 shadow-sm border-gray-100">
                    <div className="w-32 h-20 flex items-center justify-center text-lm-black font-bold text-xl">June 2022</div>
                    <span className="font-semibold text-lm-black text-lg">IELTS: 7.5</span>
                </div>
                <div className="flex flex-row items-center gap-2 border-2 pr-4 shadow-sm border-gray-100">
                    <div className="w-32 h-20 flex items-center justify-center text-lm-black font-bold text-xl">July 2022</div>
                    <span className="font-semibold text-lm-black text-lg">IELTS: 7.5</span>
                </div>
            </div>
        </div>
    )
};

export default Other;
