import {Link, useNavigate} from "react-router-dom";
import {AiFillGithub} from "react-icons/ai";
import {useState} from "react";
import API_CONSTANTS from "../../api/api_constants";

const MyWorkItem = ({image, title, tags, github, description, projectId}) => {
    const [isGitHover, setIsGitHover] = useState(false);
    const navigate = useNavigate();

    const handleLearnMoreClick = () => {
        navigate(`/portfolio/${projectId}`);
    };

    return (
        <div className="flex flex-col justify-between
                        gap-12 w-96 border-2 shadow-md">
           <div className="flex flex-col gap-2">
               <img
                   src={API_CONSTANTS.base_url + image}
                   alt={title}
                   className="w-96 h-64 object-cover"
               />
               <p className="font-semibold text-lg p-3 h-24">{title}</p>
           </div>

           <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row justify-between p-3">
                        <Link className={`flex flex-row gap-2 items-center w-fit border-2 p-2 rounded-md
                                    ${isGitHover ? "bg-lm-black text-lm-white transition duration-300" : "transition duration-300"}`}
                              onMouseEnter={()=> setIsGitHover(true)}
                              onMouseLeave={()=> setIsGitHover(false)}
                              to={github}
                              target="_blank"
                        >
                            <AiFillGithub size={26} className={`${isGitHover ? "text-lm-white" : "text-lm-black"}`}/>
                            <span className={`font-semibold text-lm-black ${isGitHover ? "text-lm-white" : "text-lm-black"}`}>Source code</span>
                        </Link>
                        <button type="button" className="hidden w-fit flex items-center">
                            <span className="font-semibold text-lm-pink">Learn more</span>
                        </button>
                    </div>

                    {description && (
                        <div className="p-3">
                            <button type="button" className="p-2" onClick={handleLearnMoreClick}>
                                <span className="font-bold text-lm-pink">Learn more</span>
                            </button>
                        </div>
                    )}
                </div>
                <hr/>
                <div className="flex flex-row gap-3 p-3 flex-wrap
                            text-base font-semibold text-gray-500">
                    {tags.map((tag, idx) => {
                        return (
                            <span key={idx}>{tag}</span>
                        )
                    })}
                </div>
           </div>
        </div>
    )
};

export default MyWorkItem;
