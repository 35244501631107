import API_CONSTANTS from "./api_constants";

const URL = "/contact";

export async function contact_me(requestData) {
    const path_segment = "/contact_me";
    const path = API_CONSTANTS.base_url + URL + path_segment;

    try {
        const response = await fetch(path, {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": API_CONSTANTS.auth_token
            }, body: JSON.stringify(requestData)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (API_CONSTANTS.is_debug) {
            console.log("contact_me response data: ", data);
        }

        return data;
    } catch (error) {
        if (API_CONSTANTS.is_debug) {
            console.error('contact_me error:', error);
        }

        throw error;
    }
}
