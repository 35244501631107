import MyWorkItem from "../Shared/MyWorkItem";
import Layout from "../Shared/Layout";
import CarTreeIc from "./images/car-tree.jpg";
import VoiceRecognitionIc from "./images/voice-recognition.png";
import PortfolioWeb1 from "./images/portfolio-web-app.png";
import ChannelPostingBotIc from "./images/channel-posting.png";
import {useEffect, useState} from "react";
import {get_all_projects} from "../../api/projects_api";

const Portfolio = () => {
    const [projects, setProjects] = useState([]);

    const requestGetAllProjects = () => {
        get_all_projects().then(response => {
            if (response && response.projects) {
                setProjects(response.projects);
            }
        });
    };

    useEffect(()=> {
        requestGetAllProjects();
    }, []);

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <div className="flex flex-col text-lm-black font-Quicksand flex flex-col gap-16 pt-16
                            lg:pt-0 items-center">
                <h2 className="text-2xl font-bold">My works</h2>
                <div className="flex flex justify-center flex-wrap gap-6">
                    {/*<MyWorkItem image={CarTreeIc}
                                title="Object detection: Machine Learning application built using Python for identification of cars and trees in images"
                                tags={["Python", "Machine Learning", "Pytorch"]}
                                github="https://github.com/AzizbekAvazov/ITNPAI1-202223_Spring-Trees-and-cars-detection"
                    />
                    <MyWorkItem image={VoiceRecognitionIc}
                                title="Voice classification: Machine Learning application built using Python for identification of voices of different people"
                                tags={["Python", "Machine Learning", "Pytorch"]}
                                github="https://github.com/AzizbekAvazov/voice-classifier"
                    />
                    <MyWorkItem image={PortfolioWeb1}
                                title="Web application: Fully responsive one-page portfolio developed using ReactJS, TailwindCSS, and framer-motion for subtle animations"
                                tags={["ReactJS", "Javascript", "Web"]}
                                github="https://github.com/AzizbekAvazov/reactjs-tailwind-portfolio-onepage"
                    />
                    <MyWorkItem image={ChannelPostingBotIc}
                                title="Telegram BOT: Coded in Java Spring Boot, this Telegram Bot allows the creation of adverts in channels that the user administrates"
                                tags={["Java", "Spring Boot", "Telegram BOT"]}
                                github="https://github.com/AzizbekAvazov/telegrambot-channel-posting"
                    />*/}

                    {projects && (
                        projects.map(project => (
                            <div key={project.projectId} className="project-item">
                                <MyWorkItem image={project.image}
                                            title={project.title}
                                            tags={project.tags.split(",")}
                                            github={project.github}
                                            description={project.description}
                                            projectId={project.projectId}
                                />
                            </div>
                        ))
                    )}
                </div>
            </div>
        </Layout>
    )
};

export default Portfolio;
