import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {get_secret_token} from "../../api/admin_api";
import Layout from "../Shared/Layout";
import 'react-quill/dist/quill.snow.css';
import {IoMdCreate} from "react-icons/io";

const Admin = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [accessToken, setAccessToken] = useState("");

    useEffect(() => {
        get_secret_token().then((response)=> {
            const accessToken = new URLSearchParams(location.search).get('accessToken');
            setAccessToken(accessToken);

            // Check if accessToken is present and valid
            if (accessToken !== response.secret_token) {
                // Redirect or show an unauthorized message
                navigate('/');
            }
        });


    }, [navigate, location.search]);

    return (
        <div>
            <Layout>
                <div className="font-Quicksand text-lm-black flex flex-col gap-12">
                    <div className="flex flex-row gap-6">
                        <Link
                            to={{
                                pathname: "/admin/insertProject",
                                search: `?accessToken=${accessToken}`,
                            }}
                            itemProp="Hello"
                            className="w-56 h-12 bg-lm-blue text-lm-white rounded-md border-2
                                       flex flex-row gap-2 items-center justify-center
                                       hover:bg-opacity-90"
                        >
                            <IoMdCreate size={24} className="text-lm-black"/>
                            <span className="font-bold">New project</span>
                        </Link>
                        <Link
                            to={{
                                pathname: "/admin/insertPost",
                                search: `?accessToken=${accessToken}`,
                            }}
                            className="w-56 h-12 bg-lm-blue text-lm-white rounded-md border-2
                                       flex flex-row gap-2 items-center justify-center
                                       hover:bg-opacity-90"
                        >
                            <IoMdCreate size={24} className="text-lm-black"/>
                            <span className="font-bold">New post</span>
                        </Link>
                    </div>

                </div>
            </Layout>
        </div>
    )
};

export default Admin;
