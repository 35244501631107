import Layout from "../Shared/Layout";
import Hero from "./Hero";
import Skills from "./Skills";
import Stack from "./Stack";
import MyWork from "./MyWork";

const Home = () => {

    return (
        <div className="bg-lm-white">
            <Layout>
                <Hero/>
            </Layout>

            <Layout>
                <Skills/>
            </Layout>

            <Layout>
                <Stack/>
            </Layout>

            <Layout>
                <MyWork/>
            </Layout>
        </div>
    )
};

export default Home;
