import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Layout from "../Shared/Layout";
import {IoArrowBackCircle} from "react-icons/io5";

const InsertPost = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [accessToken, setAccessToken] = useState("");

    useEffect(() => {
        const accessToken = new URLSearchParams(location.search).get("accessToken");
        setAccessToken(accessToken);

        // Check if accessToken is present and valid
        if (!accessToken) {
            navigate("/");
        }
    }, [location.search]);

    return (
        <div>
            <Layout>
                <div className="font-Quicksand text-lm-black flex flex-col gap-12">
                    <Link
                        to={{
                            pathname: "/admin",
                            search: `?accessToken=${accessToken}`,
                        }}
                        className="w-fit"
                    >
                        <IoArrowBackCircle size={38} className="text-lm-pink"/>
                    </Link>
                </div>
            </Layout>
        </div>
    )
};

export default InsertPost;
