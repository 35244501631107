import {motion} from "framer-motion";
import {Link} from "react-router-dom";
import CBUIc from "./images/cbu-logo.svg";
import FBIc from "./images/fb-logo.png";
import GlobalVoicesIc from "./images/global_voices.jpg";

const plang = "underline underline-offset-4 font-semibold decoration-lm-pink decoration-2";
const ptech = "text-lm-blue font-semibold";

const Work = () => {
    return (
        <div className="flex flex-col gap-12">
            <h2 className="text-2xl font-bold z-10">Work Experience</h2>

            <div className="flex flex-col gap-6">
                <motion.div
                    className="flex flex-col p-4 gap-6 border-2 shadow-md"
                    initial={{opacity: 0, scale: 0}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.3
                    }}
                >
                    <div className="lg:flex lg:flex-row lg:gap-0 gap-2
                                    flex flex-col text-center justify-between items-center">
                        <Link to="https://cbu.uz/en/" target="_blank">
                            <motion.img
                                src={CBUIc}
                                alt="IUT"
                                className="w-28 h-24"
                                initial={{ rotate: 0}}
                                animate={{ rotate: 360}}
                                transition={{
                                    duration: 0.3,
                                    delay: 1,
                                    repeat: Infinity,
                                    repeatDelay: 5
                                }}
                            />
                        </Link>
                        <span className="text-xl font-semibold">Central Bank of Uzbekistan</span>
                        <span className="text-xl font-semibold">Part time</span>
                    </div>
                    <div className="lg:flex lg:flex-row flex flex-col justify-between lg:gap-0 gap-2">
                        <span className="text-lg font-semibold">Intern</span>
                        <span className="text-lg font-semibold">October 2019 - September 2020</span>
                    </div>
                    <p>During my Computer Science Bachelor's degree, I interned at the Information and Technology department of the Central Bank of Uzbekistan. In this role, I collaborated with a team responsible for ensuring the compliance of financial institutions with Central Bank norms. My tasks included generating reports analyzing the financial services market, assessing the availability and functionality of essential software including mobile applications for these institutions.</p>
                </motion.div>

                <motion.div
                    className="flex flex-col p-4 gap-6 border-2 shadow-md"
                    initial={{opacity: 0, scale: 0}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.3
                    }}
                >
                    <div className="lg:flex lg:flex-row lg:gap-0 gap-2
                                    flex flex-col text-center justify-between items-center">
                        <Link to="https://www.fido-biznes.uz/en/" target="_blank">
                            <motion.img
                                src={FBIc}
                                alt="IUT"
                                className="w-32 h-24"
                                initial={{ rotate: 0, scale: 1.2}}
                                animate={{ rotate: 360}}
                                transition={{
                                    duration: 0.3,
                                    delay: 1,
                                    repeat: Infinity,
                                    repeatDelay: 5
                                }}
                            />
                        </Link>
                        <span className="text-xl font-semibold">Fido-Biznes ltd.</span>
                        <span className="text-xl font-semibold">Full time / Part time</span>
                    </div>
                    <div className="lg:flex lg:flex-row flex flex-col justify-between lg:gap-0 gap-2">
                        <span className="text-lg font-semibold">Backend developer</span>
                        <div className="flex flex-col text-right gap-2">
                            <span className="text-lg font-semibold">September 2020 - September 2022 [Full time]</span>
                            <span className="text-lg font-semibold">September 2022 - April 2024 [Part time]</span>
                        </div>
                    </div>
                    <p>As a Software Developer at Fido-Biznes, I participated in software development for financial institutions. My involvement encompassed various technologies:</p>
                    <ul>
                        <li>
                            - Coded <span className={plang}>PL/SQL</span> functions for backend operations.
                        </li>
                        <li>
                            - Created dynamic web pages using <span className={ptech}>Java Servlet Pages</span> and
                            fixed errors within CRM systems of banks.
                        </li>
                        <li>
                            - Engineered a secure Telegram BOT on <span
                            className={plang}>Java Spring Boot</span> for user-friendly banking operations.
                        </li>
                        <li>
                            - Coded and contributed to the middleware <span className={ptech}>API</span> using <span
                            className={ptech}>Java Spring Boot</span> for communication between <span
                            className={ptech}>ReactJS</span> and the database.
                        </li>
                    </ul>
                    <p>Upon starting my Master's degree in the UK in September 2022, I transitioned into a remote part-time role.</p>
                </motion.div>

                <motion.div
                    className="flex flex-col p-4 gap-6 border-2 shadow-md"
                    initial={{opacity: 0, scale: 0}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.3
                    }}
                >
                    <div className="lg:flex lg:flex-row lg:gap-0 gap-2
                                    flex flex-col text-center justify-between items-center">
                        <Link to="https://www.globalvoices.com/" target="_blank">
                            <motion.img
                                src={GlobalVoicesIc}
                                alt="IUT"
                                className="w-16 h-16"
                                initial={{ rotate: 0, scale: 1.2}}
                                animate={{ rotate: 360}}
                                transition={{
                                    duration: 0.3,
                                    delay: 1,
                                    repeat: Infinity,
                                    repeatDelay: 5
                                }}
                            />
                        </Link>
                        <span className="text-xl font-semibold">Global Voices ltd.</span>
                        <span className="text-xl font-semibold">Full time</span>
                    </div>
                    <div className="lg:flex lg:flex-row flex flex-col justify-between lg:gap-0 gap-2">
                        <span className="text-lg font-semibold">Full-stack developer</span>
                        <div className="flex flex-col text-right gap-2">
                            <span className="text-lg font-semibold">April 2024 - current</span>
                        </div>
                    </div>
                    <p>As a Full-Stack Developer at Global Voices Ltd., I have been integral to the development and maintenance of our web applications, focusing on enhancing business operations through advanced technology solutions. Key responsibilities:</p>
                    <ul>
                        <li>
                            - Utilizing <span className={plang}>Node.js</span> to build robust and scalable APIs that serve as the backbone of our applications, ensuring seamless data flow and functionality.
                        </li>
                        <li>
                            - Implementing dynamic and responsive user interfaces using frameworks such as <span className={ptech}>Next.js</span> and <span className={ptech}>React.js</span>, delivering a smooth and engaging user experience.
                        </li>
                        <li>
                            - Identifying, troubleshooting, and resolving issues on our portal, thereby optimizing business operations and improving overall system efficiency.
                        </li>
                        <li>
                            - Utilizing Amazon Web Services (AWS) for the deployment and management of applications, ensuring high availability, scalability, and performance.
                        </li>
                    </ul>
                </motion.div>
            </div>
        </div>
    )
};

export default Work;
