import {Link} from "react-router-dom";
import MyWorkItem from "../Shared/MyWorkItem";
import {motion} from "framer-motion";
import CarTreeIc from "./images/projects/car-tree-detection.jpg";
import VoiceRecognitionIc from "./images/projects/voice-classification.png";
import PortfolioWeb1 from "./images/projects/portfolio-web-app.png";
import {get_all_projects} from "../../api/projects_api";
import {useEffect, useState} from "react";

const MyWork = () => {
    const [projects, setProjects] = useState([]);

    const requestGetAllProjects = () => {
        get_all_projects().then(response => {
            if (response && response.projects) {
                setProjects(response.projects.slice(0, 3));
            }
        });
    };

    useEffect(()=> {
        requestGetAllProjects();
    }, []);

    return (
        <div className="text-lm-black font-Quicksand
                       gap-16 flex flex-col"
        >
            <div className="flex flex-row
                            justify-center">
                <h2 className="text-2xl font-bold">
                    My work
                </h2>
            </div>

            <div className="flex flex-row justify-center gap-8 flex-wrap">
                {/*<MyWorkItem image={CarTreeIc}
                            title="Object detection: Machine Learning application built using Python for identification of cars and trees in images"
                            tags={["Python", "Machine Learning", "Pytorch"]}
                            github="https://github.com/AzizbekAvazov/ITNPAI1-202223_Spring-Trees-and-cars-detection"
                />
                <MyWorkItem image={VoiceRecognitionIc}
                            title="Voice classification: Machine Learning application built using Python for identification of voices of different people"
                            tags={["Python", "Machine Learning", "Pytorch"]}
                            github="https://github.com/AzizbekAvazov/voice-classifier"
                />
                <MyWorkItem image={PortfolioWeb1}
                            title="Web application: Fully responsive one-page portfolio developed using ReactJS, TailwindCSS, and framer-motion for subtle animations"
                            tags={["ReactJS", "Javascript", "Web"]}
                            github="https://github.com/AzizbekAvazov/reactjs-tailwind-portfolio-onepage"
                />*/}
                {projects && (
                    projects.map(project => (
                        <div key={project.projectId} className="project-item">
                            <MyWorkItem image={project.image}
                                        title={project.title}
                                        tags={project.tags.split(",")}
                                        github={project.github}
                                        description={project.description}
                                        projectId={project.projectId}
                            />
                        </div>
                    ))
                )}
            </div>

            <div
                className="flex justify-center"
            >
                <motion.div
                    initial={{scale: 1}}
                    whileHover={{scale: 1.1}}
                >
                    <Link className="flex flex-row items-center w-fit gap-2" to="/portfolio">
                        <div className="flex flex-col">
                            <div className="flex flex-row">
                                <div className="border-2 bg-lm-pink w-4 h-4"/>
                                <div className="border-2 bg-lm-pink w-4 h-4"/>
                            </div>
                            <div className="flex flex-row">
                                <div className="border-2 bg-lm-pink w-4 h-4"/>
                                <div className="border-2 bg-lm-pink w-4 h-4"/>
                            </div>
                        </div>
                        <span className="font-semibold">View all works</span>
                    </Link>
                </motion.div>
            </div>
        </div>
    )
};

export default MyWork;
