import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Portfolio from "./components/Portfolio/Portfolio";
import Contact from "./components/Contact/Contact";
import Navbar from "./components/Shared/Navbar";
import Footer from "./components/Shared/Footer";
import Admin from "./components/Admin/Admin";
import InsertProject from "./components/Admin/InsertProject";
import InsertPost from "./components/Admin/InsertPost";
import ProjectDetail from "./components/Portfolio/ProjectDetail";

function App() {
  return (
      <BrowserRouter>
          <div>
              <Navbar/>
              <Routes>
                  <Route path="/" element={<Home/>}/>
                  <Route path="/about" element={<About/>}/>
                  <Route path="/portfolio" element={<Portfolio/>}/>
                  <Route path="/contact" element={<Contact/>}/>
                  <Route path="/admin" element={<Admin/>}/>
                  <Route path="/admin/insertProject" element={<InsertProject />}/>
                  <Route path="/admin/insertPost" element={<InsertPost/>} />
                  <Route path="/portfolio/:projectId" element={<ProjectDetail />} />
              </Routes>
              <Footer/>
          </div>
      </BrowserRouter>
  );
}

export default App;
