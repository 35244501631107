import AzizbekPhoto from "./images/AVATAR.png";
import {motion} from "framer-motion";
import {AiFillLinkedin} from "react-icons/ai";
import {Link} from "react-router-dom";

const Hero = () => {

    return (
        <div className="text-lm-black lg:flex
                        lg:flex-row lg:gap-20
                        lg:justify-center
                        lg:text-left text-center
                        flex
                        flex-col-reverse gap-6
                        items-center
                        pt-16
                        lg:pt-0 overflow-x-hidden">
            <div className="flex flex-col gap-6 max-w-screen-md">
                <div
                    className="flex flex-col gap-2"
                >
                    <h1
                        className="lg:text-3xl text-4xl font-Quicksand font-bold"
                    >
                        Hi, I am <span className="text-lm-blue">Azizbek</span>
                    </h1>
                    <motion.h2
                        className="text-xl font-Quicksand font-semibold"
                    >
                        A Software Developer
                    </motion.h2>
                </div>
                <h3
                    className="text-xl font-Quicksand bg-lm-pink
                               rounded-md p-2 text-lm-white"
                >
                    I have a strong background in Computer Science and in developing API's in Java, web applications in Javascript / ReactJS. Additionally, I have an experience in coding Python, complemented by a Master's degree in Artificial Intelligence.
                </h3>
                <div className="flex flex-row items-center gap-3">
                    <div className="h-1 w-14 bg-lm-black
                                    rounded-md"/>
                    <div className="flex flex-row items-center gap-2
                                bg-lm-blue w-fit text-lm-white
                                px-3 py-1 rounded-md"
                    >
                        <AiFillLinkedin size={24}/>
                        <h3 className="text-lg font-semibold">
                            <Link to="https://www.linkedin.com/in/azizbekavazov/" target="_blank">
                                Connect
                            </Link>
                        </h3>
                    </div>
                </div>
            </div>

            <img
                src={AzizbekPhoto}
                className="lg:w-60 w-44"
                alt="Azizbek"
            />
        </div>
    )
};

export default Hero;
