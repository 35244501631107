import {motion} from "framer-motion";
import IUTIc from "./images/iut-logo.png";
import UOSIc from "./images/uos-logo.png";
import {useRef} from "react";
import {Link} from "react-router-dom";

const plang = "underline underline-offset-4 font-semibold decoration-lm-pink decoration-2";
const ptech = "text-lm-blue font-semibold";

const Education = () => {
    const eduScrollRef_1 = useRef(null);
    const eduScrollRef_2 = useRef(null);

    return (
        <div ref={eduScrollRef_1} className="flex flex-col gap-12">
            <h2 className="text-2xl font-bold z-10">Education</h2>

            <div className="flex flex-col gap-6">
                <motion.div
                    className="flex flex-col p-4 gap-6 border-2 shadow-md"
                    initial={{opacity: 0, scale: 0}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.3
                    }}
                    viewport={{ root: eduScrollRef_1 }}
                >
                    <div className="lg:flex lg:flex-row lg:gap-0 gap-2
                                    flex flex-col text-center justify-between items-center">
                        <Link to="https://inha.uz/" target="_blank">
                            <motion.img
                                src={IUTIc}
                                alt="IUT"
                                className="w-28 h-24"
                                initial={{ rotate: 0}}
                                animate={{ rotate: 360}}
                                transition={{
                                    duration: 0.3,
                                    delay: 1,
                                    repeat: Infinity,
                                    repeatDelay: 5
                                }}
                            />
                        </Link>

                        <span className="text-xl font-semibold">Inha University in Tashkent</span>
                        <span className="text-xl font-semibold">Bachelors degree</span>
                    </div>
                    <div className="lg:flex lg:flex-row flex flex-col justify-between lg:gap-0 gap-2">
                        <span className="text-lg font-semibold">Bachelors in Computer Science</span>
                        <span className="text-lg font-semibold">2016 - 2020</span>
                    </div>
                    <p>My Computer Science degree at Inha University in Tashkent delivered a comprehensive introduction to the dynamic world of programming. One pivotal aspect was the <span className={plang}>Computer Algorithms module</span>, which furnished me with a solid foundation in essential algorithms such as <span className={ptech}>Dijkstra's algorithm</span>, <span className={ptech}>red-black trees</span>, and <span className={ptech}>bubble sort</span>. These algorithms serve as fundamental tools in the daily repertoire of software developers, providing me with practical skills in sorting, searching, and optimization.</p>

                    <p>Another key component of the curriculum was the <span className={plang}>Data Structures module</span>, which deepened my understanding of storage types widely employed in programming languages. This encompassed a thorough exploration of <span className={ptech}>arrays</span>, <span className={ptech}>matrices</span>, <span className={ptech}>linked lists</span>, and <span className={ptech}>heaps</span>. The practical coding sessions in the <span className={ptech}>C</span> programming language associated with this module were invaluable, reinforcing theoretical concepts with hands-on experience.</p>

                    <p>Beyond the core components, the degree program mandated the completion of practical modules like <span className={plang}>Unix Programming</span>, <span className={plang}>Internet Programming</span>, and <span className={plang}>Application Programming in Java</span>. These modules placed a strong emphasis on hands-on coding and software development, providing me with real-world applications of the theoretical knowledge gained.</p>

                    <p>These examples merely scratch the surface of the diverse content covered in the course. Additionally, modules such as <span className={plang}>Linear Algebra</span>, <span className={plang}>Engineering Mathematics</span>, <span className={plang}>Calculus</span>, and <span className={plang}>Physics</span> contributed to my theoretical understanding, playing a crucial role in honing my problem-solving skills. The combination of theoretical and practical components in the curriculum has equipped me with a well-rounded skill set and a solid foundation for my journey in the field of computer science.</p>
                </motion.div>

                <motion.div
                    className="flex flex-col p-4 gap-6 border-2 shadow-md"
                    initial={{opacity: 0, scale: 0}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.3
                    }}
                    viewport={{ root: eduScrollRef_2 }}
                >
                    <div className="lg:flex lg:flex-row lg:gap-0 gap-2
                                    flex flex-col text-center justify-between items-center">
                        <Link to="https://www.stir.ac.uk/" target="_blank">
                            <motion.img
                                src={UOSIc}
                                alt="University of Stirling"
                                className="w-24 h-24"
                                initial={{ rotate: 0}}
                                animate={{ rotate: 360}}
                                transition={{
                                    duration: 0.3,
                                    delay: 1,
                                    repeat: Infinity,
                                    repeatDelay: 5
                                }}
                            />
                        </Link>
                        <span className="text-xl font-semibold">University of Stirling</span>
                        <span className="text-xl font-semibold">Masters degree</span>
                    </div>
                    <div className="lg:flex lg:flex-row flex flex-col justify-between lg:gap-0 gap-2">
                        <span className="text-lg font-semibold">Masters in Artificial Intelligence</span>
                        <span className="text-lg font-semibold">2022 - 2023</span>
                    </div>
                    <p>Pursuing a Master's degree in <span className={ptech}>Artificial Intelligence</span> at the University of Stirling immersed me in a program entirely dedicated to comprehending the fundamental concept of "training" programs for autonomous task performance. A notable component of the curriculum was the <span className={plang}>Deep Learning for Vision and NLP module</span>, where successful completion of a project showcased my ability to develop a robust and accurate computer vision program. This program effectively detected cars and trees in images, demonstrating my proficiency in training <span className={ptech}>Machine Learning models</span>, preparing datasets, and instructing programs for precise detection.</p>

                    <p>The journey of crafting this project also involved addressing challenges like dealing with imbalanced datasets and errors, for which modules such as <span className={plang}>Representing and Manipulating Data</span> and <span className={plang}>Machine Learning</span> provided valuable insights on effective handling. Throughout the course, I acquired proficiency in programming with <span className={plang}>Python</span> and gained competence in managing substantial volumes of data.</p>
                </motion.div>
            </div>
        </div>
    )
};

export default Education;
