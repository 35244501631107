import Layout from "../Shared/Layout";
import BriefSummary from "./BriefSummary";
import Education from "./Education";
import Work from "./Work";
import Other from "./Other";

const About = () => {
    return (
        <div className="bg-lm-white">
            <Layout>
                <div className="text-lm-black font-Quicksand flex flex-col gap-16 pt-16 lg:pt-0">
                    <BriefSummary/>
                    <Education/>
                    <Work/>
                    <Other/>
                </div>
            </Layout>
        </div>
    )
};

export default About;
