import Layout from "../Shared/Layout";
import {useEffect, useState} from "react";
import PopupMessage from "./PopupMessage";
import * as emailjs from "@emailjs/browser";
import {contact_me} from "../../api/contact_api";

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [isEmailCorrect, setIsEmailCorrect] = useState(true);
    const [isNameCorrect, setIsNameCorrect] = useState(true);
    const [isMessageCorrect, setIsMessageCorrect] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [status, setStatus] = useState(0);

    const checkEmailFormat = () => {
        // Regular expression for a basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Test the email against the regex
        return emailRegex.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name) {
            setIsNameCorrect(false);
        }

        if (!checkEmailFormat(email)) {
            setIsEmailCorrect(false);
        }

        if (!message) {
            setIsMessageCorrect(false);
        }

        if (email && name && checkEmailFormat(email)) {
            /*const requestData = {
                from_name: name,
                from_email: email,
                html_message: message + "\n" + "from email: " + email
            }

            const serviceId = "service_c7vkyem";
            const templateId = "template_uw4i308";
            const publicKey = "kYiW5maIU875pUrlh";

            emailjs.send(serviceId, templateId, requestData, publicKey).then(
                (result) => {
                    setStatus(0);
                    setIsOpen(true);
                    setMessage("");
                },
                (error) => {
                    setStatus(1);
                    setIsOpen(true);
                }
            );*/

            const requestData = {
                name: name,
                email: email,
                message: message
            };

            contact_me(requestData).then((response)=> {
                if (response && response.status === 0) {
                    setStatus(0);
                    setIsOpen(true);
                    setMessage("");
                } else {
                    setStatus(1);
                    setIsOpen(true);
                }
            }).catch((err)=> {
                setStatus(1);
                setIsOpen(true);
            });
        }
    };

    const isOpenCallback = (state) => {
        setIsOpen(state);
    };

    useEffect(()=> {
        setIsNameCorrect(true);
    }, [name]);

    useEffect(()=> {
        setIsEmailCorrect(true);
    }, [email]);

    useEffect(()=> {
        setIsMessageCorrect(true);
    }, [message]);

    return (
        <Layout>
            <div className="relative text-lm-black bg-lm-white font-Quicksand flex flex-col items-center
                           pt-16 lg:pt-0 gap-8">
                <h2 className="text-2xl font-bold z-10">Contact me</h2>
                <form onSubmit={handleSubmit} className="flex flex-col gap-6 border-2
                                                         lg:w-[600px] sm:w-[500px]
                                                         w-80 px-12 py-8 rounded-md shadow-md z-10 bg-lm-white">
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-2">
                            <label className="block font-bold" htmlFor="name">
                                Name
                            </label>
                            {
                                !isNameCorrect && (<span className="font-bold text-red-800">(* Name should not be empty)</span>)
                            }
                        </div>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lm-black"
                            placeholder="Your Name"
                            required
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-2 items-center">
                            <label className="block font-bold" htmlFor="email">
                                Email
                            </label>
                            {
                                !isEmailCorrect && (<span className="font-bold text-red-800">(* Invalid email format)</span>)
                            }
                        </div>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lm-black"
                            placeholder="Your Email"
                            required
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-2">
                            <label className="block font-bold" htmlFor="message">
                                Message
                            </label>
                            {
                                !isMessageCorrect && (<span className="font-bold text-red-800">(* Message should not be empty)</span>)
                            }
                        </div>
                        <textarea
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="w-full px-3 py-2 resize-none border border-gray-300 rounded-md focus:outline-none focus:border-lm-black"
                            rows="4"
                            placeholder="Your Message"
                            required
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="bg-lm-blue text-white h-14 font-semibold py-2 px-4 rounded-md hover:bg-opacity-90
                                    focus:outline-none active:bg-lm-blue"
                        onSubmit={handleSubmit}
                    >
                        Submit
                    </button>
                </form>
            </div>

            <PopupMessage isOpen={isOpen} status={status} isOpenCallback={isOpenCallback}/>
        </Layout>
    )
};

export default Contact;
